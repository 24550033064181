export function resolveHostUsingEnvironmentInfo(host: string): string {
    switch (window.env?.ENVIRONMENT) {
        case 'sgov':
            return 'staging.gov.uipath-dev.com';
        case 'agov':
            return 'alpha.gov.uipath-dev.com';
        case 'pgov':
            return 'govcloud.uipath.us';
        case 'alp':
            return 'alpha.uipath.com';
        case 'nst':
            return 'plt-nst-tms-we-01-apim.azure-api.net';
        case 'stg':
            return 'staging.uipath.com';
        case 'prd':
            return 'cloud.uipath.com';
        case 'k8s':
        case 'dedicated':
            return process.env.REACT_APP_MSI_IDENTITY_HOST_LOCAL ?? host;
        default:
            // eslint-disable-next-line no-console
            console.warn('Invalid environment for resolving HOST. Look into AuthDomainResolver.ts', window.env?.ENVIRONMENT);
            return host;
    }
}
